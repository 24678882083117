import React from "react"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import Case from "./../images/suitcase.svg"
import Cap from "./../images/cap.svg"
import "./../style/journey.scss"
import SEO from "../components/seo"

const Journey = () => (
  <>
    <SEO title="Journey" />
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="August 2018 - present"
        icon={<Case />}
      >
        <h3 className="vertical-timeline-element-title">
          Full Stack Developer{" "}
          <span className="vertical-timeline-element-subtitle">- Remote</span>
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          Self-Employed & OSS Contributor
        </h4>
        <p>
          Develop projects mainly with Javascript(ES6+), React and Ruby on Rails
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="December 2017 - May 2018"
        icon={<Case />}
      >
        <h3 className="vertical-timeline-element-title">
          Front-end Developer{" "}
          <span className="vertical-timeline-element-subtitle">- Onsite</span>
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          Techlets Islamabad, Pakistan
        </h4>
        <p>
          Developed responsive Web Apps with best UI/UX practices. Worked with
          team on a project for a company which has over 50K+ employees in 40+
          countries using MEAN stack. My responsibilities involve contributions
          in HTML/Sass, Angular, and Cordova.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="March 2017 - May 2017"
        icon={<Cap />}
      >
        <h3 className="vertical-timeline-element-title">
          Front-end development{" "}
          <span className="vertical-timeline-element-subtitle">
            - Nanodegree
          </span>
        </h3>
        <h4 className="vertical-timeline-element-subtitle">Udacity</h4>
        <p>
          Gained practical skills by learning the latest tech stack. Developed
          several projects which were reviewed & approved by Udacity.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="December 2016 - August 2018"
        icon={<Case />}
      >
        <h3 className="vertical-timeline-element-title">
          Web Developer{" "}
          <span className="vertical-timeline-element-subtitle">- Remote</span>
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          Global Tech Trading International FL, United States
        </h4>
        <p>
          Planned, created, launched, and managed websites which improves
          company growth rate. Strong emphasis on user-centered design,
          usability standards, and human factors testing.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="November 2015 - November 2016"
        icon={<Case />}
      >
        <h3 className="vertical-timeline-element-title">
          IT Manager{" "}
          <span className="vertical-timeline-element-subtitle">- Remote</span>
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          Baby Shower Corner LLC TX, United States
        </h4>
        <p>
          Managed an e-commerce store, achieved 25% increase in conversion rate.
          Handled tasks included SEO, online advertising, customizing themes,
          etc.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="September 2012 - January 2017"
        icon={<Cap />}
      >
        <h3 className="vertical-timeline-element-title">
          Bachelors in Computer Science
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          National University of Computer & Emerging Sciences, Pakistan
        </h4>
        <p>
          Core included Algorithms, AI, Data Structures, OOAD, Software
          Engineering etc. Electives included Web Programming, Data Mining,
          Bioinformatics etc.
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </>
)

export default Journey
